import React from 'react';
import { AppBar, Toolbar, Button, Menu, MenuItem, IconButton, Box, Stack, Badge, Avatar, Grid } from '@mui/material';
import { Favorite, ShoppingCart, AccountCircle, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';

// Sample logo image import
import Logo from '../Assets/logo192.png';

function UpperNavbar() {
    const navigate = useNavigate();
    const location = useLocation();

    const [wallpapersAnchorEl, setWallpapersAnchorEl] = React.useState(null);
    const [printingsAnchorEl, setPrintingsAnchorEl] = React.useState(null);
    const [aboutUsAnchorEl, setAboutUsAnchorEl] = React.useState(null);

    const handleWallpapersMenuOpen = (event) => {
        setWallpapersAnchorEl(event.currentTarget);
    };

    const handleWallpapersMenuClose = () => {
        setWallpapersAnchorEl(null);
    };

    const handlePrintingsMenuOpen = (event) => {
        setPrintingsAnchorEl(event.currentTarget);
    };

    const handlePrintingsMenuClose = () => {
        setPrintingsAnchorEl(null);
    };

    const handleAboutUsMenuOpen = (event) => {
        setAboutUsAnchorEl(event.currentTarget);
    };

    const handleAboutUsMenuClose = () => {
        setAboutUsAnchorEl(null);
    };

    return (
        <Grid container justifyContent="center" alignItems="center" marginTop="40px" position={'absolute'} zIndex={100}>
            <Grid item>
                <Box sx={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)', borderRadius: '40px', width: '80px', height: '80px', backgroundColor: '#1976d2', marginRight:'40px' }}>
                    <Avatar alt="Logo" src={Logo} sx={{ width: '80px', height: '80px', backgroundColor: '#1976d2' }} />
                </Box>
            </Grid>
            <Grid item>
                <AppBar position="static" sx={{ borderRadius: '40px' }}>
                    <Toolbar sx={{ height: '80px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Button
                            color="inherit"
                            onClick={() => navigate('/')}
                            sx={{ color: location.pathname === '/' ? 'yellow' : 'inherit' }}
                        >
                            Home
                        </Button>
                        <Button
                            color="inherit"
                            aria-controls="wallpapers-menu"
                            aria-haspopup="true"
                            onClick={handleWallpapersMenuOpen}
                            endIcon={wallpapersAnchorEl ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                            sx={{ color: location.pathname.startsWith('/wallpapers') ? 'yellow' : 'inherit' }}
                        >
                            Wallpapers
                        </Button>
                        <Menu
                            id="wallpapers-menu"
                            anchorEl={wallpapersAnchorEl}
                            open={Boolean(wallpapersAnchorEl)}
                            onClose={handleWallpapersMenuClose}
                        >
                            <MenuItem onClick={() => { handleWallpapersMenuClose(); navigate('/wallpapers') }}>Wallpaper 1</MenuItem>
                            <MenuItem onClick={() => { handleWallpapersMenuClose(); navigate('/wallpapers') }}>Wallpaper 2</MenuItem>
                            <MenuItem onClick={() => { handleWallpapersMenuClose(); navigate('/wallpapers') }}>Wallpaper 3</MenuItem>
                        </Menu>
                        <Button
                            color="inherit"
                            aria-controls="printings-menu"
                            aria-haspopup="true"
                            onClick={handlePrintingsMenuOpen}
                            endIcon={printingsAnchorEl ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                            sx={{ color: location.pathname.startsWith('/printings') ? 'yellow' : 'inherit' }}
                        >
                            Printings
                        </Button>
                        <Menu
                            id="printings-menu"
                            anchorEl={printingsAnchorEl}
                            open={Boolean(printingsAnchorEl)}
                            onClose={handlePrintingsMenuClose}
                        >
                            <MenuItem onClick={() => { handlePrintingsMenuClose(); navigate('/printings') }}>Printing 1</MenuItem>
                            <MenuItem onClick={() => { handlePrintingsMenuClose(); navigate('/printings') }}>Printing 2</MenuItem>
                            <MenuItem onClick={() => { handlePrintingsMenuClose(); navigate('/printings') }}>Printing 3</MenuItem>
                        </Menu>
                        <Button
                            color="inherit"
                            onClick={() => navigate('/wallpaper-rolls')}
                            sx={{ color: location.pathname === '/wallpaper-rolls' ? 'yellow' : 'inherit' }}
                        >
                            Wallpaper Rolls
                        </Button>
                        <Button
                            color="inherit"
                            onClick={() => navigate('/canvas-painting')}
                            sx={{ color: location.pathname === '/canvas-painting' ? 'yellow' : 'inherit' }}
                        >
                            Canvas Painting
                        </Button>
                        <Button
                            color="inherit"
                            aria-controls="about-us-menu"
                            aria-haspopup="true"
                            onClick={handleAboutUsMenuOpen}
                            endIcon={aboutUsAnchorEl ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                            sx={{ color: location.pathname === '/about-us' ? 'yellow' : 'inherit' }}
                        >
                            About Us
                        </Button>
                        <Menu
                            id="about-us-menu"
                            anchorEl={aboutUsAnchorEl}
                            open={Boolean(aboutUsAnchorEl)}
                            onClose={handleAboutUsMenuClose}
                        >
                            <MenuItem onClick={() => { handleAboutUsMenuClose(); navigate('/about-us') }}>About Us 1</MenuItem>
                            <MenuItem onClick={() => { handleAboutUsMenuClose(); navigate('/about-us') }}>About Us 2</MenuItem>
                            <MenuItem onClick={() => { handleAboutUsMenuClose(); navigate('/about-us') }}>About Us 3</MenuItem>
                        </Menu>
                        <Box sx={{ flexGrow: 1 }} />
                        <Stack direction="row" spacing={2}>
                            <IconButton color="inherit">
                                <Badge badgeContent={3} color="error">
                                    <Favorite />
                                </Badge>
                            </IconButton>
                            <IconButton color="inherit">
                                <Badge badgeContent={5} color="error">
                                    <ShoppingCart />
                                </Badge>
                            </IconButton>
                        </Stack>
                        <IconButton color="inherit" sx={{ ml: 1 }}>
                            <AccountCircle />
                        </IconButton>
                    </Toolbar>
                </AppBar>
            </Grid>
        </Grid>
    );
}

export default UpperNavbar;