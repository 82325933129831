import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    fontFamily: 'Arial, sans-serif',
    position: 'relative',
    overflow: 'hidden',
    background: 'linear-gradient(45deg, #EE7752, #E73C7E, #23A6D5, #23D5AB)',
    backgroundSize: '400% 400%',
    animation: '$gradientAnimation 15s ease infinite',
  },
  heading: {
    marginBottom: theme.spacing(4),
    textAlign: 'center',
    color: '#fff',
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
  },
  countdown: {
    fontSize: '2rem',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#fff',
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
  },
  '@keyframes gradientAnimation': {
    '0%': {
      backgroundPosition: '0% 50%',
    },
    '50%': {
      backgroundPosition: '100% 50%',
    },
    '100%': {
      backgroundPosition: '0% 50%',
    },
  },
}));

const LaunchCountdown = ({ launchDate }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(launchDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const classes = useStyles();

  return (
    <div className={classes.countdown}>
      {timeLeft.days}d {timeLeft.hours}h {timeLeft.minutes}m {timeLeft.seconds}s
    </div>
  );
};

const Home = () => {
  const classes = useStyles();
  const launchDate = new Date();
  launchDate.setDate(launchDate.getDate() + 15);

  return (
    <div className={classes.root}>
      <Typography variant="h1" className={classes.heading}>
        <b>Coming Soon!</b>
      </Typography>
      <Typography variant="h3" className={classes.countdown}>
        <b>Launching in:</b>
      </Typography>
      <LaunchCountdown launchDate={launchDate}/>
    </div>
  );
};

export default Home;
