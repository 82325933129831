import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import UpperNavbar from './Assets/UpperNavbar';
import Home from './Webpages/Home';


const App = () => {
  return (
    <Router>
    <UpperNavbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/wallpapers" element={<Home />} />
        <Route path="/printings" element={<Home />} />
        <Route path="/about" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default App;
